//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.listItem{
    border-radius: 4px;
    padding:0 12px;
    //background: linear-gradient(180deg, #16314E 0%, #2D5072 100%);
    background: linear-gradient(89.18deg, rgba(99, 255, 125, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-width: 0px 0px 1px 1px;
    border-style: solid;
    border-color: #63FF7D;

    .listContent{
        display: flex; flex-direction: column;grid-gap:8px; padding: 8px 0;

        .itemRow {
            display: flex;
            .itemName {
                font-size: 16px;
                font-weight: 600;
                color: #63FF7D;
                flex: 1;
            }
            .itemStatus {
                font-size: 16px;
                font-weight: 600;
                color: #EDFFF6;
            }
            .itemInfo {
                min-width: 100px;
                font-size: 16px;
                font-weight: 600;
                color: #FFFFFF;
            }
        }
    }
    .listFooter{
        border-top: 1px solid rgba(255, 255, 255, 0.10);
        padding: 8px 0;font-size: 12px;line-height: 1.3;
        color: #EDFFF6;
    }

    &.listItemFail {
        background: linear-gradient(89.18deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
        border-color: #FFFFFF;
        .listContent {
            .itemRow {
                .itemName {
                    color: #FFFFFF;
                }

                .itemStatus {
                    color: #597C82;
                }
            }
        }
    }
}

.listItemFail {
    background: linear-gradient(89.18deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%) !important;
}

.action{
    display: flex;align-items: center; gap: 12px;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    padding: 16px 0;
}
