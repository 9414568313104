//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.withdraw {
  .selectGroup {
    display: flex;
    margin: 0 -6px;
    .itemSelect {
      margin: 0 6px;
    }
  }
  .form {
    border-top: none !important;
    border-bottom: none !important;
    :global {
      .adm-list-item {
        padding: 0;
        background: transparent;
        .adm-list-item-content {
          border-top: 0;
          padding: 0;
        }
        &.adm-form-item-vertical {
          .adm-form-item-label {
            margin-bottom: 10px;
            color: #fff;
            font-size: 16px;
          }
        }
      }
      .adm-list-body {
        background: transparent;
      }
      .adm-form-footer {
        padding: 0;
      }
      .adm-list-item-content-main {
        padding: 0;
      }
    }
    .formItem {
      margin-bottom: 24px;
      .itemTitle {
        font-size: 14px;
        font-weight: 600;
        color: $colorBlack;
        margin-bottom: 8px;
      }
    }
  }
  .inputFooter {
    font-size: 12px;
    color: $colorTextSecondary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
  }
  .actualAmount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    padding: 5px 0;
    border-top: 1px solid $colorBorder;
    .label {
      color: $colorTextSecondary;
    }
    .value {
      font-size: 20px;
      color: rgba(99, 255, 125, 1);
    }
  }
}

.btnMax {
  color: $colorTextSecondary;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  flex-shrink: 0;
  cursor: pointer;
  color: #fff;
  &:hover {
    color: $colorTextPrimary;
  }
}
