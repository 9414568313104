//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.exchange {
  .selectGroup {
    display: flex;
    margin: 0 -6px;
    .itemSelect {
      margin: 0 6px;
    }
  }
  .form {
    border-top: none !important;
    border-bottom: none !important;
    :global {
      .adm-list-item {
        padding: 0;
        background: transparent;
        .adm-list-item-content {
          border-top: 0;
          padding: 0;
        }
        &.adm-form-item-vertical {
          .adm-form-item-label {
            margin-bottom: 10px;
            color: #fff;
            font-size: 16px;
          }
        }
        .adm-input-element {
          padding-right: 44px;
        }
      }
      .adm-list-body {
        background: transparent;
      }
      .adm-form-footer {
        padding: 0;
      }
      .adm-list-item-content-main {
        padding: 0;
      }
    }
    .formItem {
      margin-bottom: 12px;
      position: relative;
      .itemTitle {
        font-size: 14px;
        font-weight: 600;
        color: $colorBlack;
        margin-bottom: 8px;
      }
    }
  }
  .inputFooter {
    font-size: 12px;
    color: $colorTextSecondary;
    margin-top: 8px;
    .itemInputFooter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
      span {
        &:last-child {
          color: #fff;
        }
      }
    }
  }
  .actualAmount {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    padding: 16px 0;
    border-top: 1px solid $colorBorder;
    border-bottom: 1px solid $colorBorder;
    .label {
      color: $colorTextSecondary;
    }
    .value {
      font-size: 24px;
      font-weight: 700;
      color: rgba(99, 255, 125, 1);
    }
  }
}

.btnMax {
  position: absolute;
  top: 52px;
  right: 12px;
  z-index: 9999;
  color: $colorTextSecondary;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  flex-shrink: 0;
  cursor: pointer;
  color: rgba(99, 255, 125, 1);
  font-size: 12px;
  &:hover {
    color: $colorTextPrimary;
  }
}

.exchangeRecord {
  margin-top: 36px;
  .title {
    border-bottom: 1px solid $colorBorder;
    padding-bottom: 5px;
    margin-bottom:15px;
  }
  .list {
    .item {
      display: flex;
      align-items: center;
      border-radius: 4px 0 0 4px;
      background: linear-gradient(89.18deg, rgba(99, 255, 125, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      border-left: 1px solid rgba(99, 255, 125, 1);
      border-bottom: 1px solid rgba(99, 255, 125, 1);
      padding: 8px 12px;
      margin-bottom: 3px;
      .itemLeft {
        margin-right: 12px;
      }
      .itemRight {
        width: 100%;
        flex-shrink: 1;
      }
      .itemRightTop {
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
        .label {
          color: $colorTextPrimary;
        }
        .value {
          color: rgba(99, 255, 125, 1);
          span {
            color: $colorTextPrimary;
          }
        }
      }
      .itemRightBottom {
        border-top: 1px solid $colorBorder;
        padding-top: 5px;
        font-size: 12px;
        color: $colorTextPrimary;
      }
    }
  }
}
