//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.navBg{
    background: url("/assets/images/MiningPool/nav.png") no-repeat center / 100% 100%;
}
.headerContent{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 52px;
    border-top: 1px solid transparent;
    border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
    border-bottom: 1px solid #63FF7D;

    display: flex;
    justify-content: center;
    align-items: center;
    //padding: 8px 12px;
    //height: 60px;
    //align-self: stretch;
    //color: $colorTextSecondary;
    //background:  linear-gradient(180deg, #16314E 0%, #2D5072 100%);
    //font-weight: 700;
    .headerItem{
        display: flex;
        justify-content: center;
        align-items: center;
        flex:1;
        font-size:36px;
        gap:4px;

        .headerItemTitle {
            font-size: 10px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            gap: 4px;
        }
        .headerItemNum {
            font-size: 18px;
            color: #63FF7D;
        }
    }
    .headerItem+.headerItem{
        border-left: 2px solid rgba(255, 255, 255, 0.05);
    }
    .tipsIcon{ height: 12px; width: 12px; }
}

.mainContent{
    //background: url("/assets/images/MiningPool/prod-bg.jpg") no-repeat center top/ auto 100% ;
    background: url("~@/assets/images2/miningPool/prod-bg.png") no-repeat center top;
    background-size: 100% 100%;
    flex:1;
    padding:10px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    .music{ width: 24px; height: 24px; border-radius: 50%; display: flex; align-items: center;
        justify-content: center;background-color: rgba(255, 255, 255, 0.20);
        .icon{ width: 16px; height: 16px; }
    }
}

.footerContent{
    height: 160px;
    box-sizing: border-box;
    background: url("~@/assets/images2/miningPool/footer-bg.png") no-repeat top center;
    background-size: 100% 100%;
    //padding: 24px 20px;
    padding: 20px;
    //grid-gap: 10px;
    grid-gap: 20px;
    display: flex;
    align-items: center;

    .footerImg {
        width: 118px;
        height: 118px;
        background: url("~@/assets/images2/miningPool/prod-border.png") no-repeat center;
        background-size: 100% 100%;
        padding: 2px;
        position: relative;

        .bomCatImg {
            width: 114px;
            height: 114px;
        }

        .bomCatInfo {
            width: 100%;
            height: 30px;
            background: linear-gradient(360deg, rgba(99, 255, 125, 0.5) 0%, rgba(0, 0, 0, 0.4) 100%);
            border-bottom: 2px solid #63FF7D;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3px;

            img {
                width: 20px;
                height: 20px;
            }
            .bomCatCoin {
                font-size: 20px;
                color: #FFFFFF;
            }
        }
    }

    .footerRight {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .footerRightTop {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .btnTop {
                border: 0;
                //background: transparent;
                background: #00202503;
                border-bottom: 1px solid #63FF7D;
                box-shadow: 0px -2px 8px 0px #B7F9AF4D inset;
                border-radius: 4px;
                color: #EDFFF6;
                width: 90px;
                height: 29px;
                font-size: 10px;

                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    margin-left: 3px;
                }
            }
        }
    }
}

.apply_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    height: 72px;
    //width: 190px;
    width: 100%;
    border-radius: 10px;
    border: unset;
    padding: 0;
    margin: 0;
    //background: url("/assets/images/MiningPool/apply.png") no-repeat center/ 100% 100% ;
    background: url("~@/assets/images2/miningPool/btn-apply.png") no-repeat center/ 100% 100% ;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1px;
    .icon{ font-size: 36px; width: 36px; }
    transform: scale(1,1) translate(0px,0px);
    transition: all 0.1s ease;
    position: relative;
    overflow: hidden;
    &:hover{
        // transition: background 0.3s ease-in-out;
        transform:scale(0.98,0.98) translate(1px, 3px);
        transition: all 0.1s ease;
    }
    &.active {
        //background: url("/assets/images/MiningPool/applying.png") no-repeat center/ 100% 100% ;
        background: url("~@/assets/images2/miningPool/btn-active.png") no-repeat center/ 100% 100% ;
    }
    span{
        background: linear-gradient(180deg, #FFF 0%, #C3D0FF 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-family: DINPro;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 1; /* 133.333% */
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
}

.btn{
    display: flex;justify-content: center; align-items: center;gap: 4px;
    height: 32px;border-radius: 10px;border: unset;padding: 0;margin: 0;
    border-radius: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(180deg, rgba(23, 49, 74, 0.80) 0%, rgba(49, 68, 89, 0.80) 100%);
    transform: scale(1,1) translate(0px,0px);
    transition: all 0.1s ease;
    position: relative;
    overflow: hidden;
    &:hover{
        // transition: background 0.3s ease-in-out;
        transform:scale(0.98,0.98) translate(1px, 3px);
        transition: all 0.1s ease;
    }
    .icon{ width: 14px; height: 14px; }
    span{
        background: linear-gradient(180deg, #FFF 0%, #A5B2BE 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-family: DINPro;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5; /* 133.333% */
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
}





