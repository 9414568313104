//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.textarea {
  --borderColor:#FFC4B1;
  --shadowColor:rgba(255, 137, 99, 0.25);

  padding: 15px 20px;
  background: $colorTextThird;
  border-radius: 10px;

  &:hover , &.active:hover {
    border: 0;
    border-radius: 10px;
    box-shadow:0px -1px 0px 0px var(--borderColor) inset, 0px 4px 4px 0px var(--shadowColor);
  }
  :global {
    //--placeholder-color: #C2D6ED;
    --color: #fff;
    .adm-text-area-element {
      &::placeholder {
        color: --placeholder-color;
      }
    }
  }
}
