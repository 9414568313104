//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

:global {
  .common-dialog {
    .adm-center-popup-wrap {
      min-width: calc(100vw - 40px);
      max-width: calc(100vw - 40px);
    }
    .adm-center-popup-body {
      margin: none;
      padding: none;
      border: none;
      border-radius: 2px;
      background: linear-gradient(180deg, #2F666D 5.85%, #1B3437 100%);
    }
    .adm-dialog-content {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin-top: 10px;
      height: 110px;
    }
    .adm-dialog-title {
      color: #fff;
      font-size: 20px;
      height: 42px;
      margin-bottom: 0px;
      margin-top: -20px;
      padding: 0px;
    }

    .adm-dialog-footer {
      padding: 0 20px 20px;
      .adm-dialog-action-row {
        border-top: 0;
      }

      .adm-button-primary {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        min-height: 44px;
        border-radius: 2px;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        border: 0;
        box-shadow:0px -1px 0px 0px #FFC4B1 inset;
        color: rgba(0, 0, 0, 1);
      }
      .adm-button-danger {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        min-height: 44px;
        border-radius: 2px;
        font-size: 16px;
        text-align: center;
        border: 0;
        //box-shadow:0px -1px 0px 0px #4F6176 inset;
        color: #fff;
        margin-right: 20px;
        background: rgba(99, 255, 125, 0.2);
      }
    }
  }
}
