//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.button {
  --borderColor:#FFC4B1;
  --shadowColor:rgba(255, 137, 99, 0.25);
  width: 100px;
  height: 45px;
  //background: $colorPrimary;
  background: rgba(0,0,0,0.2);
  border-radius: 4px;
  transition: background-color 0.2s linear;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:active {
    opacity: 0.8;
  }
}

.buttonText {
  color: $colorWhite;
  font-style: normal;
  //font-weight: 600;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  //margin-left: 10px;
}

.small {
  width: 70px;
  height: 30px;
  border-radius: 8px;
}
