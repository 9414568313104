//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

/*
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: space-between;
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-05 10:31:55
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-26 16:18:24
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\components\commonButton\CommonButton.module.scss
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */


.primary{
  color:#000000;
  --bgColor: #FF8963;
  --borderColor:#FFC4B1;
  --shadowColor:rgba(255, 137, 99, 0.25);
  background: transparent;
  //&:before {
  //    position: absolute;
  //    content: '';
  //    display: inline-block;
  //    top: -180px;
  //    left: -200px;
  //    width: 30px;
  //    height: 100%;
  //    background-color: #FFBD70 ;
  //    animation: shiny-btn1 3s ease-in-out infinite;
  //    border: unset;
  //    opacity: 1;
  //    border-radius:0;
  //    // opacity: .1;
  //}
}


.button {
  width: 100%;
  height: 44px;
  min-height: 44px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //grid-gap: 8px;
  //background-color: var(--bgColor);
  //box-shadow: 0px -1px 0px 0px var(--borderColor) inset;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.3px;
  transition: all 0.1s ease;
  overflow: hidden;
  border: 0;
  background-image: url('../../assets/images/common/button_n.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &>span{display: flex;align-items: center; grid-gap: 4px; }
  &:hover{
    // transition: background 0.3s ease-in-out;
    transform:scale(0.98,0.98) translate(1px, 3px);
    transition: all 0.1s ease;
  }
}


.buttonShadow{
  //box-shadow:0px -1px 0px 0px var(--borderColor) inset,0px 4px 4px 0px var(--shadowColor);
}

.buttonShadow:after {
  //content: '';
  //width: 100%;
  //height: 28px;
  background: url("~@/assets/images2/btn-shadow.png") no-repeat center;
  //background-size: 100% 100%;
  //position: absolute;
  //left: 0;
  ////bottom: -26px;
  //top: 52px;
  //z-index: 1;
}

.buttonDisabled {
  //background: #A3A3A3;color: #505050;box-shadow: 0px -1px 0px 0px #DDD inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  //transform: unset;
  color: #eeeeee;
  background: url("~@/assets/images2/btn-bg__disabled.png") no-repeat center;
  background-size: 100% 100%;
  &:hover{ transform:unset; }
}
.buttonSmall {
  font-size: 12px;
  min-width: 50px;
  height: 25px;
  min-height: 25px;
  border-radius: 0px;
  letter-spacing: 3px;
}

.cancel{
  //--color:#FFFFFF;
  //--bgColor: #233954;
  //--borderColor:#4F6176;
  //--shadowColor:rgba(255, 137, 99, 0.25);
  //background: #597C82;
  //background: #10484F;
  //color: #CCEADB;
  background: rgba(89, 124, 130, 0.2);
}

.reset {
  background: #10484F;
  color: #CCEADB;
}

.confirm {
  background: rgba(0, 0, 0, 1);
}


//@-webkit-keyframes shiny-btn1 {
//  0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
//  80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
//  81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
//  100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
//}
//@keyframes shiny-btn1 {
//  0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
//  80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
//  81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
//  100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
//}







