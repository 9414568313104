//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.body {
  // background: linear-gradient(180deg, #123556 0%, #071A2C 100%);
  //background: url("/assets/images/page-bg.jpg") no-repeat center top/auto 100%;
  background: linear-gradient(180deg, #006270 5.85%, #053036 100%);
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {

  }

  .loading{
    flex-grow: 1;
    overflow: auto; //页面需要顶部固定，可以打开此注释

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    flex-grow: 1;
    overflow: auto; //页面需要顶部固定，可以打开此注释
  }

  .content2 {
    background: $colorAliceBlue;
  }

  .padding {
    padding: 20px;
  }

  .flex {
    display: flex;
    flex-direction: column;
  }
}
