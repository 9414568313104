//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.custom-selector {
  .adm-selector-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    //padding: 8px 16px;
    font-size: 16px;
    //border-radius: 10px;
    //background: #233954;
    //color: #83A0C2;
    height: 42px;
    color: #7DBABE;
    background: #10484F;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.00);
  }

  .adm-selector-item-active,
  .adm-selector-item-multiple-active {
    //border-radius: 10px;
    //border: 1px solid #FF8963;
    //background: rgba(255, 137, 99, 0.50);
    //color: #FFF;
    color: #63FF7D;
    border: 1px solid #63FF7D;
    background: #63FF7D1A;
  }
}
