//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.body {
  //min-height: 40vh;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 16px 16px;
}

.content {
  border: 1px solid $colorTextPrimary;
  background: transparent;
  border-radius: 10px;
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .itemContent {
    flex-grow: 1;
  }

  .arrow {
    //padding: 8px;
    & > svg {
      width: 16px;
      height: 16px;
      color: $colorTextPrimary;
    }
  }
}

.item {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 1);

  .title {

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;

    color: $colorBlack;
  }
}

.itemBorderBottom {
  border-bottom: 1px solid $colorBorder;
}
