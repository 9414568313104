//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.listItem{
    border-radius: 4px;
    padding:0 12px;
    //background: linear-gradient(180deg, #16314E 0%, #2D5072 100%);
    border-width: 0px 0px 1px 1px;
    border-style: solid;
    border-color: #63FF7D;
    background: linear-gradient(89.18deg, rgba(99, 255, 125, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    height: 52px;
    display: flex;
    align-items: center;

    .itemType {
        font-size: 16px;
        color: #FFFFFF;
    }
    .itemEarn {
        font-size: 24px;
        color: #63FF7D;
        flex: 1;
        margin-left: 5px;
    }
    .itemTime {
        font-size: 12px;
        color: #EDFFF6;
    }
}
.listContent{
    flex: 1;
    display: flex; flex-direction: column;grid-gap:8px; padding: 8px 0;
}
.listFooter{
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    padding: 8px 0;font-size: 12px;line-height: 1.3;
}
.action{
    display: flex;align-items: center; gap: 12px;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    padding: 16px 0;
}
