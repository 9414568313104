//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

:global {
  .common-modal {
    .adm-center-popup-wrap {
      min-width: calc(100vw - 40px);
      max-width: calc(100vw - 40px);
    }
    .adm-center-popup-body {
      border-radius: 2px;
      background: linear-gradient(180deg, #2F666D 5.85%, #1B3437 100%);
    }
    .adm-modal-content {
      color: #fff;
      padding: 20px;
      padding-bottom: 0;
    }
    .adm-modal-title {
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url('../../assets/images/common/header-title.png');
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      height: 42px;
      color: #fff;
      font-size: 20px;
      margin-bottom: 0;
      margin-top: -20px;
    }
  }

}
