//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.contentFixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.content {
  width: 100%;
  height: 60px;
  //background: red;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .logo {
    width: auto;
    //height: 28px;
    height: 16px;
  }

  .icon {
    width: 30px;
    height: 30px;

    &:nth-child(n+2) {
      margin-left: 8px;
    }
  }

  .center {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
  }

  .right {
    margin-left: 10px;



  }


}
