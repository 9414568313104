//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.grid{ 
    display: grid; grid-gap: 16px; grid-template-columns: repeat(3,1fr);
}


.grid_item{ 
    aspect-ratio: 7/8; padding: 6px;border-radius: 4px;overflow: hidden;
    position: relative;top: 0;left: 0;z-index: 0;
    display: flex;flex-direction: column; justify-content: space-between;
    background: linear-gradient(180deg, #123556 0%, #071A2C 100%);
    background-repeat: no-repeat;background-position: center center;background-size: 100% auto;
    &::before{ 
        content: ""; 
        position: absolute;top: 0; left: 0;z-index: 100; 
        height: 100%; width: 100%; 
    }
    .icon{ width: 14px; height: 14px; }
    .coin{ 
        display: flex; align-items: center; justify-content: center;line-height:1.5; gap:2px;
        img{width: 12px; height: 12px;} 
    }
    &.gray-frame::before { background:url("/assets/images/nft/gray-frame.png") no-repeat center / 100% 100%; }		
    &.pink-frame::before { background:url("/assets/images/nft/pink-frame.png") no-repeat center / 100% 100%; }
    &.coppery-frame::before { background:url("/assets/images/nft/coppery-frame.png") no-repeat center / 100% 100%; }		
    &.purple-frame::before { background:url("/assets/images/nft/purple-frame.png") no-repeat center / 100% 100%; }	
    &.silvery-frame::before { background:url("/assets/images/nft/silvery-frame.png") no-repeat center / 100% 100%; }		
    &.blue-frame::before { background:url("/assets/images/nft/blue-frame.png") no-repeat center / 100% 100%; }	
    &.golden-frame::before { background:url("/assets/images/nft/golden-frame.png") no-repeat center / 100% 100%; }		
    &.cyan-frame::before { background:url("/assets/images/nft/cyan-frame.png") no-repeat center / 100% 100%; }	
    &.color-frame::before { background:url("/assets/images/nft/color-frame.png") no-repeat center / 100% 100%; }		
    &.green-frame::before { background:url("/assets/images/nft/green-frame.png") no-repeat center / 100% 100%; }	
    &.red-frame::before { background:url("/assets/images/nft/red-frame.png") no-repeat center / 100% 100%; }		
    &.yellow-frame::before { background:url("/assets/images/nft/yellow-frame.png") no-repeat center / 100% 100%; }
}

.nft{ 
    aspect-ratio: 7/8; padding: 4px;border-radius: 10px;overflow: hidden;
    position: relative;top: 0;left: 0;z-index: 1;
    display: flex;flex-direction: column; justify-content: space-between;
    background-repeat: no-repeat;background-position: center center;background-size: 100% auto;
    border: 3px solid;
    &.gray-frame { border-image: linear-gradient(to bottom,#677B8E, #31404F) 10;color:#C2D6ED }		
    &.pink-frame { border-image: linear-gradient(to bottom,#D9A0CC, #A045A1) 10;}	
    &.coppery-frame { border-image: linear-gradient(to bottom,#976F6A, #764237) 10; }		
    &.purple-frame { border-image: linear-gradient(to bottom,#AFA0D9, #6E45A1) 10; }	
    &.silvery-frame { border-image: linear-gradient(to bottom,#FFFFFF, #677480) 10;color:#464646 }		
    &.blue-frame { border-image: linear-gradient(to bottom,#ACB9FF, #5276F6) 10; }	
    &.golden-frame { border-image: linear-gradient(to bottom,#FFDE8A, #C07F43) 10; }		
    &.cyan-frame { border-image: linear-gradient(to bottom,#A0D9D2, #3097C3) 10;color:#FFFFFF }	
    &.color-frame { border-image: linear-gradient(to bottom,#A181FF, #44A1A7) 10; }		
    &.green-frame { border-image: linear-gradient(to bottom,#B2D9A0, #63A145) 10; }	
    &.red-frame { border-image: linear-gradient(to bottom,#D9A0A0, #B83636) 10; }		
    &.yellow-frame { border-image: linear-gradient(to bottom,#D9D0A0, #C2B42D) 10; }
}

.tag{ 
    display: inline-flex;justify-content: center;align-items: center;gap: 10px;
    padding: 2px 8px;border-radius: 4px;font-size:12px;font-weight: 700; 
    &.gray-frame { background: linear-gradient(180deg,#677B8E 0%, #31404F 100%);color:#C2D6ED }		
    &.pink-frame { background: linear-gradient(180deg,#D9A0CC 0%, #A045A1 100%);}	
    &.coppery-frame { background: linear-gradient(180deg,#976F6A 0%, #764237 100%); }		
    &.purple-frame { background: linear-gradient(180deg,#AFA0D9 0%, #6E45A1 100%) }	
    &.silvery-frame { background: linear-gradient(180deg,#FFFFFF 0%, #677480 100%);color:#464646 }		
    &.blue-frame { background: linear-gradient(180deg,#ACB9FF 0%, #5276F6 100%) }	
    &.golden-frame { background: linear-gradient(180deg,#FFDE8A 0%, #C07F43 100%) }		
    &.cyan-frame { background: linear-gradient(180deg,#A0D9D2 0%, #3097C3 100%);color:#FFFFFF }	
    &.color-frame { background: linear-gradient(180deg,#A181FF 0%, #44A1A7 100%) }		
    &.green-frame { background: linear-gradient(180deg,#B2D9A0 0%, #63A145 100%) }	
    &.red-frame { background: linear-gradient(180deg,#D9A0A0 0%, #B83636 100%) }		
    &.yellow-frame { background: linear-gradient(180deg,#D9D0A0 0%, #C2B42D 100%) }	
}

.intro{ 
    padding: 12px 0; display: flex; flex-direction: column; grid-gap: 4px; 
    .coin{ 
        display: flex; align-items: center; justify-content: center;line-height:1.3; gap:2px;
        img{width: 20px; height: 20px;} 
        span{font-size: 20px; font-weight: 700; } 
    }
}
.border{ 
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: rgba(255, 255, 255, 0.10);
}
.borderBottom{ 
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.btns{ 
    display: grid; grid-template-columns: repeat(2,1fr) ;grid-gap: 12px;
}



.mainContent{ padding: 10px 20px;}

//shop-filter
.filter{ 
    display: flex; align-items: center; justify-content: space-between;
    font-weight: 700; font-size: 20px; line-height: 1.5;grid-gap: 4px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);padding: 4px 0;
}
.action{ 
    display: flex;align-items: center; gap: 12px; 
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    padding: 16px 0;
}
.form {
    border-top: none !important;
    border-bottom: none !important;
    :global {
      .adm-list-item {background: transparent;padding-left:unset;
        .adm-list-item-content {border-top: 0;padding: 0;}
      }
      .adm-list-body { background: transparent; padding: 12px 0; border-top: unset;}
      .adm-form-footer {padding: 12px 0;}
      .adm-list-item-content-main {padding: 0;}
    }
    .formItem { margin-bottom: 20px;
      .itemTitle {font-size: 14px;font-weight: 600;color: $colorBlack;margin-bottom: 8px;}
    }
}

.bg {
  background-image: url('../../assets/images/nft/frame-background.png');
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  background-image: url('../../assets/images/common/header-title.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}


// inputAction

.inputAction{ 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    font-size: 30px;
    gap: 4px;
    border-radius: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.00);
    background: #233954;
}

.bannerContent{ 
    height: 162px;
}

.swiperWrapper {
    height: 160px;
    display: flex;align-items: center;justify-content: center;
    border-top: 1px solid $colorPrimary;
    border-bottom: 1px solid $colorPrimary;
}

.confirm {
  background: #fff;
}

