//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.input {
  --borderColor: rgb(38, 102, 111);
  //--shadowColor:rgba(255, 137, 99, 0.25);
  --placeholder-color: #597C82;
  padding: 12px 12px;
  //border-radius: 10px;
  border-radius: 4px;
  //background: #83A0C2;
  //border: 1px solid #83A0C2;
  border: 1px solid #26666F;
  background: #004650;
  //color: #597C82;
  color: #FFFFFF;
  height: 46px;

  &:hover {
    //box-shadow: 0px -1px 0px 0px var(--borderColor) inset, 0px 4px 4px 0px var(--shadowColor);
    border: 1px solid #63FF7D;
  }

  &::placeholder {
    color: #597C82 !important;
  }

}

.input-border {
  //border: 1px solid #83A0C2;
}

.input-border-bottom {
  //border-bottom: 1px solid #83A0C2;
}

.input-start {
  .adm-input-element {
    text-align: start;
  }
}

.input-center {
  .adm-input-element {
    text-align: center;
  }
}

.input-end {
  .adm-input-element {
    text-align: end;
  }
}

.input-custom-filter {
  height: 46px;
  padding: 10px;
}

.active {
  &:hover {
    box-shadow: 0px -1px 0px 0px var(--borderColor) inset, 0px 4px 4px 0px var(--shadowColor);
  }
}

.active-bottom {
  &:hover {
    border-bottom: 1px solid $colorBlue;
  }
}

.adm-input-disabled {
  opacity: 1 !important;

  &:hover {
    border: 1px solid $colorDivider;
  }
}
