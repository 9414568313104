//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
}

:root:root {}

.animated-router-container {
  height: 100%;
}


/* 公共样式 */
* {
  box-sizing: border-box;
}

ul {
  padding: 0;
  list-style: none
}

html {
  overflow-y: scroll;
}

body,
html {
  margin: 0;
  padding: 0
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/*辅助类*/
// .sheng{ text-overflow:ellipsis; white-space:nowrap; overflow:hidden;}
// .center-block{display:block; margin:0 auto;}
// .clear{ clear:both}
// .pm{ padding:0!important; margin:0!important}
// .fright{ float:right} .fleft{ float:left}
// .block{ display:block} .inline{ display:inline-block}
// .relative{ position:relative}.absolute{ position:absolute}
// .show{ display:block;} .hidden{ display:none}
// .col-md-20 {width: 20%; }

/*文字类*/

.bold {}

.black {
  color: $colorBlack !important;
}

.blue {
  color: $colorBlue !important;
}

.white {
  color: $colorWhite !important;
}

.gray {
  color: $colorGray !important;
}

.aliceblue {
  color: $colorAliceBlue !important;
}


.text-black {
  color: $colorBlack !important;
}

.text-blue {
  color: $colorBlue !important;
}

.text-white {
  color: $colorAliceBlue !important;
}

.text-gray {
  color: $colorGray !important;
}


.h1 {
  font-size: 40px;
  font-weight: bold;
}

.h2 {
  font-size: 32px;
  font-weight: bold;
}

.h3 {
  font-size: 24px;
  font-weight: bold;
}

.h4 {
  font-size: 20px;
  font-weight: bold;
}

.h5 {
  font-size: 18px;
  font-weight: bold;
}

.h6 {
  font-size: 16px;
  font-weight: bold;
}

.h7 {
  font-size: 14px;
  font-weight: bold;
}


.f12 {
  font-size: 12px !important;
}

.f14 {
  font-size: 14px !important;
}

.f16 {
  font-size: 16px !important;
}

.f18 {
  font-size: 18px !important;
}

.f20 {
  font-size: 20px !important;
}

.f24 {
  font-size: 24px !important;
}

.f28 {
  font-size: 28px !important;
}

.f32 {
  font-size: 32px !important;
}

.f36 {
  font-size: 36px !important;
}

/*段落类*/
.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-left {
  text-align: left
}

.indent2 {
  text-indent: 2em;
}

.line10 {
  line-height: 1;
}

.line13 {
  line-height: 1.3;
}

.line14 {
  line-height: 1.4;
}

.line15 {
  line-height: 1.5;
}



.h01 {
  min-height: 1px;
}

.h02 {
  min-height: 2px;
}

.h03 {
  min-height: 3px;
}

.h04 {
  min-height: 4px;
}

.h05 {
  min-height: 5px;
}

.h06 {
  min-height: 6px;
}

.h07 {
  min-height: 7px;
}

.h08 {
  min-height: 8px;
}

.h08 {
  min-height: 9px;
}

.h09 {
  min-height: 9px;
}

.h10 {
  min-height: 10px;
}

.h15 {
  min-height: 15px;
}

.h16 {
  min-height: 16px;
}

.h20 {
  min-height: 20px;
}

.h24 {
  min-height: 24px;
}

.h25 {
  min-height: 25px;
}

.h30 {
  min-height: 30px;
}

.h40 {
  min-height: 50px;
}

.h50 {
  min-height: 50px;
}

.h60 {
  min-height: 60px;
}

.h100 {
  min-height: 100px;
}

.w01 {
  width: 1px;
}

.w02 {
  width: 2px;
}

.w03 {
  width: 3px;
}

.w04 {
  width: 4px;
}

.w05 {
  width: 5px;
}

.w06 {
  width: 6px;
}

.w07 {
  width: 7px;
}

.w08 {
  width: 8px;
}

.w09 {
  width: 9px;
}

.w10 {
  width: 10px;
}

.w15 {
  width: 15px;
}

.w20 {
  width: 20px;
}

.w30 {
  width: 30px;
}

.w40 {
  width: 40px;
}

.w50 {
  width: 50px;
}

.w60 {
  width: 60px;
}

.w70 {
  width: 70px;
}

.w80 {
  width: 80px;
}

.w90 {
  width: 90px;
}

.w100 {
  width: 100px;
}

.w110 {
  width: 110px;
}

.w120 {
  width: 120px;
}

.w130 {
  width: 130px;
}

.w140 {
  width: 140px;
}

.w150 {
  width: 150px;
}

.w200 {
  width: 200px;
}

.w300 {
  width: 300px;
}

.w400 {
  width: 400px;
}


/*边框类 border+线*/
.bk {
  border: 1px solid #ccc
}

.bk2 {
  border: 1px solid #f00
}

.bk-left {
  border-left: 1px solid #eee;
}

.bk-right {
  border-right: 1px solid #eee;
}

.bk-top {
  border-top: 1px solid #eee;
}

.bk-bottom {
  border-bottom: 1px solid #eee;
}

.bk-top-bottom {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
}

.bk-left-right {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

.bk-red {
  border: 1px solid #f00
}

.radius0 {
  border-radius: 0 !important
}

.radius5 {
  border-radius: 5 !important
}

.radius6 {
  border-radius: 6 !important
}

.radius7 {
  border-radius: 7 !important
}

.radius8 {
  border-radius: 8 !important
}

.radius9 {
  border-radius: 9 !important
}

.radius10 {
  border-radius: 10 !important
}

/*公共*/
.btn {
  height: 56px;
  min-width: 112px;
  font-size: 18px;
  border-radius: 12px;
  border: none;
  color: #fff;
  font-weight: bold;
  background: linear-gradient(0deg, #3283FF, #8EBBFF);
  /*width: 100%;*/
}

.btn.primary {
  background: linear-gradient(0deg, #C0D8FF, #ECF2FF);
  color: #3283FF;
}

.btn:disabled {
  background: #F9FAFB;
  color: #111827;
}

.btn.min {
  min-width: 60px;
  height: 26px;
  border-radius: 8px;
  font-size: 14px;
}

.title-1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
}

.title-2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
}

.title-3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
}

.body-1 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.body-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
}

.caption-1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}