//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.management {
  
}
.btnAdd {
  color: #fff;
  width: 24px;
  height: 24px;
}

.curAccountItem {
  padding: 8px 12px;
  border-radius: 4px;
  background: linear-gradient(89.18deg, rgba(99, 255, 125, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

  border-left: 1px solid rgba(99, 255, 125, 1);
  border-bottom: 1px solid rgba(99, 255, 125, 1);

  margin-bottom: 10px;
  .label {
    padding-bottom: 8px;
    border-bottom: 1px solid $colorBorder;

    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(99, 255, 125, 1);
  }
  .value {
    padding-top: 8px;
    font-size: 12px;
    color: $colorTextSecondary;
    display: flex;
    align-items: center;
  }
  .iconCopy {
    width: 14px;
    height: 14px;
    margin-left: 3px;
  }
}

.subAccountItem {
  padding: 8px 12px;
  border-radius: 4px;
  background: linear-gradient(89.18deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  border-left: 1px solid rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 1);

  margin-bottom: 10px;
  .label {
    padding-bottom: 8px;
    border-bottom: 1px solid $colorBorder;

    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .value {
    padding-top: 8px;
    font-size: 12px;
    color: $colorTextSecondary;
    display: flex;
    align-items: center;
  }
  .iconCopy {
    width: 14px;
    height: 14px;
    margin-left: 3px;
  }
}

.currentAccount {
  margin-bottom: 15px;
}

.groupTitle {
  margin-bottom: 10px;
  border-bottom: 1px solid $colorBorder;
  padding-bottom: 5px;
}

.switchText {
  text-align: center;
  font-size: 16px;
  span {
    color: $colorPrimary;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  background-image: url('../../../assets/images/common/header-title.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

