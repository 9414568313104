//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.content {
  --borderColor:#FFC4B1;
  --shadowColor:rgba(255, 137, 99, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56x;
  padding: 12px 10px;
  background: #83A0C2;
  &:hover {
    border: 1px solid #83A0C2;
    box-shadow:0px -1px 0px 0px var(--borderColor) inset, 0px 4px 4px 0px var(--shadowColor);
  }
  .left {
    float: none;
    margin-right: 18px;


    & > svg {
      color: #9CA3AF;
      width: 24px;
      height: 24px;
    }
  }

  .center {
    flex-grow: 1;

  }

  .right {
    flex-basis: 40%;
    display: flex;
    justify-content: flex-end;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #fff;
    // &:hover {
    //   color: $colorBlue;
    // }
  }

  &Border {
    border: 1px solid #83A0C2;
    border-radius: 10px;
  }

  &BorderBottom {
    border-bottom: 1px solid $colorDivider;
  }

  &Active {
    &:hover {
      border: 1px solid #83A0C2;
      box-shadow:0px -1px 0px 0px var(--borderColor) inset, 0px 4px 4px 0px var(--shadowColor);
    }
  }

  &Error {
    // border: 1px solid $colorRed;

    // .right {
    //   color: $colorRed;
    // }

    // &:hover {
    //   border: 1px solid $colorRed;
    // }

    // &:hover .right {
    //   color: $colorRed;
    // }
  }

  &Active-bottom {
    &:hover {
      border-bottom: 1px solid $colorBlue;
    }

    &:hover .right {
      color: $colorBlue;
    }
  }

  :global(.adm-input-disabled:hover) {
    border: none;
  }
  :global(.adm-input) {
    --placeholder-color: #C2D6ED;
    --color: #fff;
  }
}


.input {
  height: 48px;
  padding: 12px 10px;
  background: $colorWhite;
  color: #fff;
  .adm-input-element {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;

    color: #fff;
  }
}


.input-start {
  .adm-input-element {
    text-align: start;
  }
}

.input-center {
  .adm-input-element {
    text-align: center;
  }
}

.input-end {
  .adm-input-element {
    text-align: end;
  }
}

.input-custom-filter {
  height: 40px;
  padding: 10px;
}

