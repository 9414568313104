//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.coinItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    margin-right: 10px;
    & > img {
      width: 32px;
      height: 32px;
    }
  }

  .title {
    font-size: 16px;
    color: #fff;
  }
}

.title {
  font-size: 16px;
  line-height: 1.5;
}
