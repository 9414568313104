//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.teamInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    background: linear-gradient(180deg, #16314E 0%, #2D5072 100%);
    padding: 12px;
    font-weight: 700;
    margin-top: 12px;

    .label {
        font-size: 16px;
        color: $colorTextSecondary;
    }

    .value {
        font-size: 20px;

    }
}

.teamItem {
    border-radius: 4px;
    background: linear-gradient(180deg, #16314E 0%, #2D5072 100%);
    padding: 0 12px;

    &>div+div {
        border-top: 1px solid rgba(255, 255, 255, 0.10);
    }

    .teamItemTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;

        .itemLabel {
            font-weight: 700;
        }

        .itemValue {
            color: $colorTextSecondary;
        }
    }

    .teamItemBottom {
        padding: 8px 0;
        color: $colorTextSecondary;

        .btnCopy {
            margin-left: 4px;
            width: 14px;
            height: 14px;
            color: $colorTextSecondary;
        }
    }
}

.teamTitle {
    margin-top: 20px;
    border-bottom: 1px solid $colorBorder;
    padding-bottom: 4px;
    margin-bottom: 12px;
}

.teamBox {
    display: flex;
    flex-direction: column;
}

.avatar {
    width: 140px;
    height: 140px;
}

.avatarBox {
    width: 140px;
    height: 140px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 0px rgba(255, 255, 255, 0.25) inset;
    overflow: hidden;
    margin: 0 auto;
    background: #16314E;
}

.teamTop {
    .teamName {
        font-size: 20px;
        font-weight: 700;
        margin-top: 8px;
        text-align: center;
        min-height: 29px;
    }
}