//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.accountIndex {
  padding: 0;
}
.settingList {
  margin-top: 20px;
  .settingItem {
    position: relative;
    padding: 16px 20px;
    color: #fff;
    font-size: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .uploadAvatar {
      opacity: 0;
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
    }
    &:last-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    }
    .settingItemRight {
      display: flex;
      align-items: center;
      width: 50%;
      justify-content: flex-end;
      span {
        //color: $colorTextSecondary;
        color: rgba(99, 255, 125, 1);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        display: block;
        text-align: right;
      }
      .avatar {
        width: 24px;
        height: 24px;
      }
      &::after {
        content: '';
        display: block;
        width: 6px;
        height: 12px;
        background: url('../../../assets/images/arrow_right.png') no-repeat center;
        background-size: cover;
        margin-left: 10px;
        flex-shrink: 0;
      }
    }
  }
}
.form {
  :global {
    .adm-list-body {
      border: 0;
    }
    .adm-modal-content {
      padding: 0;
    }
    .adm-list-item {
      padding: 0;
    }
    
    .adm-list-body, .adm-list-item {
      background: transparent;
    }
    .adm-list-item-content {
      padding: 0;
      border: 0;
    }
    .adm-list-item-content-main {
      padding: 10px 0;
    }
  }
  .btnGroup {
    display: flex;
    width: calc(100% + 40px);
    margin: 0 -20px;
    button {
      margin: 0 10px;
    }
  }
}
