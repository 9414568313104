//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;


.password {
  --borderColor:#FFC4B1;
  --shadowColor:rgba(255, 137, 99, 0.25);

  display: flex;
  align-items: center;
  padding: 12px 12px;
  //border: 1px #83A0C2 solid;
  border: 1px solid #FFFFFF26;
  gap: 4px;
  //border-radius: 10px;
  border-radius: 4px;
  //background: #83A0C2;
  background: rgba(0,0,0,0.15);

  &:hover {
    //border: 1px solid #83A0C2;
    //box-shadow:0px -1px 0px 0px var(--borderColor) inset, 0px 4px 4px 0px var(--shadowColor);
    border: 1px solid #63FF7D;
  }

  .icon {
    float: none;
    margin-right: 18px;
    & > svg {
      color: #9CA3AF;
      width: 24px;
      height: 24px;
    }
  }
  .input {
    flex: auto;
    color: #fff;
    //background: #83A0C2;
    //border: 1px solid #FFFFFF26;
  }
  .eye {
    flex: none;
    margin-left: 8px;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      display: block;
      color: #C2D6ED;
    }
  }
  :global(.adm-input) {
    --placeholder-color: #597C82;
    --color: #63FF7D;
  }
  //:global(.adm-list-body-inner) {
  //  margin-top: 0px;
  //}
}
