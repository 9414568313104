//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.button {
  width: 100%;
  height: 44px;
  min-height: 44px;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //grid-gap: 8px;
  //background-color: var(--bgColor);
  //box-shadow: 0px -1px 0px 0px var(--borderColor) inset;
  //font-weight: 600;
  font-size: 16px;
  //line-height: 1.5;
  text-align: center;
  letter-spacing: 1px;
  //color: var(--color);
  color: #FFFAEC;
  // transition: background 0.3s ease-in-out;
  transform: scale(1,1) translate(0px,0px);
  transition: all 0.1s ease;
  position: relative;
  //overflow: hidden;
  border: 0;
  background: url("~@/assets/images2/btn-upgrade.png") no-repeat center;
  background-size: 100% 100%;

  &>span{display: flex;align-items: center; grid-gap: 4px; }
  &:hover{
    // transition: background 0.3s ease-in-out;
    transform:scale(0.98,0.98) translate(1px, 3px);
    transition: all 0.1s ease;
  }
}

.buttonShadow{
}

.buttonDisabled {

}

.buttonSmall {
  font-size: 12px;
  height: 28px;
  min-height: 28px;
  border-radius: 0px;
}
