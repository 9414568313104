//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-09-15 10:48:19
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-01-19 14:59:56
 * @FilePath: \weapp\wepayToB-h5\src\assets\css\index.scss
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
@import url('assets/css/font.scss');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'DIN';
}
code {font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;}
html, body, #root {height: 100%;}

.animated-router-container{ height: 100%;}
/* 公共样式 */
*{ box-sizing: border-box;}
ul{ padding: 0; margin: 0; padding-left:1em;}
// list-style:none
html{overflow-y:scroll;}
body,html,h1,h2,h3,h4,h5,h6,p{ margin:0;padding:0}
a{text-decoration:none;}
a:hover{ text-decoration:none; }
input{
  &::placeholder {
    color: #C2D6ED !important;
  }
}

/* ==================
          文字类
 ==================== */

.text-bold{font-weight: 700;}
.text-8{font-size:8px!important;}
.text-10{font-size:10px!important;}
.text-12{font-size:12px!important;}
.text-14{font-size:14px!important;}
.text-16{font-size:16px!important;}
.text-18{font-size:18px!important;}
.text-20{font-size:20px!important;}
.text-24{font-size:24px!important;}
.text-28{font-size:28px!important;}
.text-32{font-size:32px!important;}
.text-36{font-size:36px!important;}
.text-sub{word-wrap: normal;white-space:nowrap; overflow: hidden; text-overflow: ellipsis; }
.text-underline {
  text-decoration: underline;
}

.text-word{word-wrap: break-word; }
.text-center{ text-align:center}
.text-right{ text-align:right}
.text-left{ text-align:left}
.indent2{text-indent: 2em;}
.line10{ line-height:1;}
.line13{ line-height:1.3;}
.line14{ line-height:1.4;}
.line15{ line-height:1.5;}

.text-white{ color: #FFFFFF;}
.text-aliceBlue{ color: #C2D6ED;}
.text-orange{ color: #FF8963;}
.text-green{color: rgba(99, 255, 125, 1);}
.text-gradient{
  background: linear-gradient(180deg, #FFF 0%, #C3D0FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}



/* ==================
          布局
 ==================== */

/*  -- flex弹性布局 -- */
.flex {display: flex;}
.basis-xs {flex-basis: 20% !important;}
.basis-sm {flex-basis: 40% !important;}
.basis-df {flex-basis: 50% !important;}
.basis-lg {flex-basis: 60% !important;}
.basis-xl {flex-basis: 80% !important;}
.flex-sub {flex: 1;}
.flex-twice {flex: 2;}
.flex-treble {flex: 3;}
.flex-shrink{ flex-shrink: 0;}

.flex-direction {flex-direction: column; }
.flex-wrap {flex-wrap: wrap;}
.align-start {align-items: flex-start;}
.align-end {align-items: flex-end;}
.align-center {align-items: center;}
.align-stretch {align-items: stretch;}
.self-start {align-self: flex-start !important;}
.self-center {align-self: center !important;}
.self-end {align-self: flex-end !important;}
.self-stretch {align-self: stretch !important;}
.align-stretch {align-items: stretch;}
.justify-start {justify-content: flex-start;}
.justify-end {justify-content: flex-end;}
.justify-center {justify-content: center;}
.justify-between {justify-content: space-between;}
.justify-around {justify-content: space-around;}
.gap4{ grid-gap: 4px;}
.gap12{ grid-gap: 12px;}
.gap20{ grid-gap: 20px;}
.gap24{ grid-gap: 24px;}


.marginLeft10{ margin-left: 10px; }
.marginLeft4{ margin-left: 4px; }
.top40{ margin-top: 40px;}
.top20{ margin-top: 20px;}
.top10{ margin-top: 10px;}
.top2{ margin-top: 2px;}
.top4{ margin-top: 4px;}


