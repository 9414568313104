//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.rule {
  word-break: break-all;
  width: 100%;

  &  * {
    word-break: break-word;
    width: 100%;
    max-width: 100%;
  }

  & h2{
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
    margin: 6px 0;
  }

  & p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    margin: 6px 0;
  }
}