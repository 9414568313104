//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.list{


  :global(.adm-form-item-label) {
    font-size: 14px;
    font-weight: bold;
    color: #111827;
  }

  :global(.adm-list-item) {
    padding-left: 0;
  }

  :global(.adm-list-item-content) {
    border-top: none;
    padding-right: 0;
  }

  :global(.adm-list-item-content-main) {
    padding: 0;
  }

  :global(.adm-list-body) {
    background-color: transparent;
  }

  :global(.adm-list-item) {
    background-color: transparent;
  }

  :global(.adm-list-body-inner) {
    display: flex; flex-direction: column;grid-gap: 12px;
  }





}
