//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.recharge {
  .selectGroup {
    display: flex;
    gap: 12px;
  }
  .qrcodeBox {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
  .qrcode {
    width: 150px;
    height: 150px;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    svg {
      width: 130px;
      height: 130px;
    }
  }
  .tip {
    font-size: 12px;
    color: $colorTextSecondary;
    width: 220px;
    text-align: center;
    margin: 0 auto;
  }
  .rechargeInfo {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    padding: 0 12px;
    .rechargeInfoItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      .rechargeInfoTitle {
        color: $colorTextSecondary;
        font-size: 12px;
      }
      .rechargeInfoContent {
        display: flex;
        align-items: center;
        font-size: 12px;
        span {
          max-width: 150px;
          overflow: hidden;
          word-wrap: break-word;
          text-align: right;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid $colorBorder;
      }
      .btnCopy {
        margin-left: 5px;
      }
    }
  }
}
