//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.createSubAccount {
  color: #fff;
  :global(.adm-form) {
    --border-top: none;
    --border-bottom: none;
  }

  .form {
    border-top: none !important;
    border-bottom: none !important;
    :global {
      .adm-list-item {
        background: transparent;
        .adm-list-item-content {
          border-top: 0;
          padding: 0;
        }
      }
      .adm-list-body {
        background: transparent;
      }
      .adm-form-footer {
        padding: 0;
      }
      .adm-list-item-content-main {
        padding: 0;
      }
    }
    .formItem {
      margin-bottom: 20px;
      .itemTitle {
        font-size: 14px;
        font-weight: 600;
        color: $colorBlack;
        margin-bottom: 8px;
      }
    }
  }
  .tips {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin: 18px 0 32px;

    font-size: 14px;
    color: $colorGray;

    & > input {
      margin-right: 8px;
    }

    & > span {
      color: $colorBlue;
    }
  }
  :global {
    .adm-form-item.adm-form-item-vertical {
      .adm-form-item-label {
        font-size: 16px;
        color: #fff;
        margin-bottom: 10px;
      }
    }
  }
  
  :global(.adm-list-item) {
    padding-left: 0;
  }
  .tip {
    font-size: 12px;
    color: rgba(255, 221, 135, 1);

    margin-top: 20px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  background-image: url('../../../../assets/images/common/header-title.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.record {
  .curItem {
    background: linear-gradient(89.18deg, rgba(99, 255, 125, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-bottom: 1px solid rgba(99, 255, 125, 1);
    border-left: 1px solid rgba(99, 255, 125, 1);
  }
  .item {
    background: linear-gradient(89.18deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    border-left: 1px solid rgba(255, 255, 255, 1);
  }
  .listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px 0px 0px 4px;

    .content {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
  
      .left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
    }
  
    .icon{
      width: 16px;
      height: 16px;
      font-weight: 600;
    }
    .listContent {
      justify-content: space-between;
      width: 100%;
      margin-left: 12px;
      .itemTitle {
        font-weight: 700;
        color: $colorTextSecondary;
        display: flex;
        justify-content: space-between;
        padding-bottom: 6px;
        border-bottom: 1px solid $colorBorder;
        .itemLabel {
          color: $colorTextSecondary;
        }
        .itemValue {
          font-weight: 700;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          &.negative {
            color: $colorTextThird;
          }
        }
      }
      .ItemFooter {
        font-size: 12px;
        color: white;
        padding-top: 4px;
        display: flex;
        justify-content: space-left;
        align-items: center;
        .intro{ 
          padding: 8px 10px; display: flex; flex-direction: column; grid-gap: 2px; 
          .coin{ 
            display: flex;
            align-items: center; justify-content: left;line-height:1.0; gap: 0px;
            img {
              width: 18px;
              height: 18px;
              margin-top: 4px;
              margin-right: 6px;
            } 
            span {
              font-size: 14px; font-weight: 700;
            } 
          }
        }
      }
      .Footer {
        display: flex;
        font-size: 12px;
        color: white;
        padding-top: 8px;
        gap: 8px;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
  .gridItem{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 58px;
    height: 20px;
    border: unset;
    padding: 0;
    border-radius: 4px;
    background: transparent;
    border-bottom: 1px solid #63FF7D;
    box-shadow: 0px -2px 8px 0px #B7F9AF4D inset;
    transform: scale(1,1) translate(0px,0px);
    transition: all 0.1s ease;
    &:hover{
        // transition: background 0.3s ease-in-out;
        transform:scale(0.98,0.98) translate(1px, 3px);
        transition: all 0.1s ease;
    }
    span{
        background: linear-gradient(180deg, #FFF 0%, #A5B2BE 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-family: DINPro;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
  }
}

