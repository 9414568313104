//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.wallets {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > button {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    min-height: 60px;

    border: none;
    background: linear-gradient(180deg, #ECF2FF 0%, #C0D8FF 100%);
    color: $colorBlue;
    border-radius: 12px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;

    text-align: center;
    letter-spacing: 0.3px;

    transition: background 0.3s ease-in-out;

    &:active {
      opacity: 0.8;
    }

    &:nth-child(n+2) {
      margin-top: 10px;
    }
  }

  .address {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;

    letter-spacing: 0.3px;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid $colorBorder;
  }

  .chain {

  }

  .balance {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-size: 14px;
    line-height: 1.5;
  }

  .bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}


.coinItem {
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  border-bottom: 1px solid $colorBorder;
  flex-grow: 1;

  .coin {
    width: 24px;
    height: 24px;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #fff;

    flex-grow: 1;
    margin: 0 6px;

    transition: color .1s linear;
  }
}

.buttonItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0;

  & > img {
    width: 36px;
    height: 36px;
    margin-bottom: 4px;

    background: $colorBlue;
    border-radius: 50%;

    color: $colorWhite;

  }

  &>svg{
    width: 36px;
    height: 36px;
    margin-bottom: 4px;
    padding: 6px;

    background: $colorBlue;
    border-radius: 50%;

    color: $colorWhite;
  }

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: $colorBlue;

  &:active {
    opacity: 0.8;
  }
}
