//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.invitation {
  .qrcode {
    width: 150px;
    height: 150px;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    margin: 0 auto;
    svg {
      width: 130px;
      height: 130px;
    }
  }
  .title {
    font-weight: 700;
    margin-top: 12px;
    text-align: center;
  }
  .invitationInfo {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    margin-top: 24px;
    padding: 0 12px;
  }
  .invitationInfoItem {
    padding: 10px 0;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .invitationInfoTitle {
      color: $colorTextSecondary;
      flex-shrink: 0;
      margin-right: 10px;
    }
    .invitationInfoContent {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      word-break: break-all;
      width: 60%;
      flex-shrink: 1;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $colorBorder;
    }
    .btnCopy {
      margin-left: 5px;
      width: 14px;
      height: 14px;
      flex-shrink: 0;
    }
  }
  .tip {
    color: rgba(255, 221, 135, 1);
    font-size: 12px;
    margin-top: 24px;
    line-height: 20px;
  }
}
