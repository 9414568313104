//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.headerContent{
    height: 70px;
    width: 100vw;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    flex-shrink: 0;
    &::-webkit-scrollbar {display: none; }
    transition: all .6s ease-in-out 0s;
	transform: translateX(0px);
    .headerBox{
        display: flex;
    }
    .headerItem{
        width: 70px; height: 100%;
        // display: flex; align-items: center;justify-content: center;
        position: relative;top: 0; left: 0;z-index: 1;
        overflow: hidden; color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size:auto 100%;
        // 遮罩
        &::before{
            content: "";
            display: flex; align-items: center;justify-content: center;
            color:rgba(255, 255, 255, 0.5);font-size: 12px;font-weight: 700;
            height: calc(100% - 1px); width: calc(100% - 1px);
            position: absolute;top: 0; left: 0;z-index: 10;
            background: rgba(0, 0, 0, 0.6);
            border:1px solid rgba(255, 255, 255, 0.15);
        }
        // 已完成
        &.mask::before{
            content: "已完成";
            font-size: 12px;
            color: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: flex-end;
            padding: 3px 0;
            box-sizing: border-box;
        }
        &.mask_En::before{
            content: "Clear";
            font-size: 12px;
            color: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: flex-end;
            padding: 3px 0;
            box-sizing: border-box;
        }
        // 进行中
        .now{
            //height: 30px;
            width: 34px;
            height: 34px;
            border: 1px solid #CCEADB;
            border-radius: 50%;
            position: absolute;
            top: calc((100% - 34px)/2);
            left: calc((100% - 34px)/2);
            z-index: 20;
        }
        // 选中状态
        &.active{
            // img{ display: none; }
            &::before{
                // content: "";
                //height: calc(100% - 3px) ; width: calc(100% - 3px);
                height: calc(100% - 2px) ; width: calc(100% - 2px);
                //box-shadow: 0px -20px 20px 0px rgba(255, 137, 99, 0.50) inset;
                border: 2px solid transparent;
                background: transparent;
                //border-image: linear-gradient(to bottom,rgba(255, 137, 99, 0), rgba(255, 204, 187, 1)) 10;
                //border-width: 1px 2px 2px 2px;
                //border-style: solid;
                border-image: linear-gradient(180deg, #63FF7D 0%, rgba(99, 255, 125, 0) 100%) 10;
                box-shadow: 0px 20px 20px 0px rgba(99, 255, 125, 0.5) inset;
            }
        }
    }
}


.mainContent{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    flex:1;display: flex;
    flex-direction: column;
    justify-content: space-between;

    .topGrid {
        padding: 0px 12px;
        display: flex;

        .topInfo {
            flex: 1;
            .topRow {
                display: flex;
                align-items: center;
                background: linear-gradient(180deg, #FFFFFF 0%, #DDFFE3 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                padding: 6px 0;
                border-bottom: 1px solid #FFFFFF;
                border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
                border-image-slice: 1;

                .topLevel {
                    font-size: 15px;
                    font-weight: 800;
                }
                .topName {
                    font-size: 20px;
                    font-weight: 800;
                    margin-left: 5px;
                    flex: 1;
                }
            }
            .topSubRow {
                display: flex;
                align-items: center;
                padding: 4px 0;

                .topLabel {
                    font-size: 10px;
                    color: #FFFFFF;
                }
                .topVal {
                    font-size: 16px;
                    color: #63FF7D;
                    margin-left: 2px;
                }
            }

            .audioBox {
                .music{ width: 22px; height: 22px; border-radius: 50%; display: flex; align-items: center;
                    justify-content: center;background-color: rgba(255, 255, 255, 0.20);
                    .icon{ width: 16px; height: 16px; }
                }
            }
        }
    }

    .bomGrid {
        display: flex;
        align-items: flex-end;
        padding: 8px;
        box-shadow: 0px -8px 8px 0px #63FF7D26 inset;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.2) 100%);

        .bomInfo {
            flex: 1;
            display: flex;
            align-items: center;

            .bomInfoRight {
                margin-left: 3px;
                .bomCont {
                    display: flex;
                    align-items: center;
                    .bomLabel {
                        font-size: 12px;
                        color: #FFFFFF;
                    }
                    .bomVal {
                        font-size: 24px;
                        color: #63FF7D;
                        margin-left: 5px;
                    }
                }
                .bomEarn {
                    display: flex;
                    align-items: center;
                    .bomLabel {
                        font-size: 10px;
                        color: #EDFFF6;
                    }
                    .bomVal {
                        font-size: 12px;
                        color: #EDFFF6;
                        margin-left: 5px;
                    }
                }
            }
        }

        .bomCat {
            width: 84px;
            height: 84px;
            background: url("~@/assets/images2/miningPool/prod-border.png") no-repeat center;
            background-size: 100% 100%;
            padding: 2px;
            position: relative;
            .bomCatImg {
                width: 80px;
                height: 80px;
            }
            .bomCatInfo {
                width: 100%;
                height: 21px;
                background: linear-gradient(360deg, rgba(99, 255, 125, 0.5) 0%, rgba(0, 0, 0, 0.4) 100%);
                border-bottom: 2px solid #63FF7D;
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 3px;

                img {
                    width: 14px;
                    height: 14px;
                }
                .bomCatCoin {
                    font-size: 14px;
                    color: #FFFFFF;
                }
            }
            .bomCatActive {
                width: 36px;
                height: 36px;
                position: absolute;
                top: 0px;
                right: 0px;
            }
        }
    }

    .mainGrid{
        width: 100%;
        display: flex;
        color:#fff;
        padding: 8px 12px;
        //background: linear-gradient(180deg, rgba(22, 49, 78, 0.70) 0%, rgba(45, 80, 114, 0.70) 100%);
        .topItem{
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
            justify-content: center;
            padding:0 10px;

        }
        .topItem.first{ align-items:flex-start; font-weight: 700; }
        .topItem+.topItem{ border-left: 1px solid #537297; }
        .padding{ padding: 0px 12px;}
        .title{ width: 70px;}

        .bottomItem{ display: flex; align-items: center; font-size: 36px; gap: 4px; padding: 0 10px; width: 100%;}
        .bottomItem+.bottomItem{ border-left: 1px solid #537297; }

        .btn{ flex: unset; width: 70px;}
        .icon{
            width: 36px; height: 36px; border-radius: 2px;background: rgba(131, 160, 194, 0.30);overflow:hidden;
            img{ height: 100%; width: 100%; }
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
        }
        .coin{
            display: flex; align-items: center; line-height:1.5; gap:2px;
            font-size: 16px;
            img{width: 16px; height: 16px;}
        }
    }
    .mainBox{
        flex: 1;padding: 10px;
        display: flex;justify-content: flex-end;

        .music{ width: 24px; height: 24px; border-radius: 50%; display: flex; align-items: center;
            justify-content: center;background-color: rgba(255, 255, 255, 0.20);
            .icon{ width: 16px; height: 16px; }
        }
    };
}

.footerContent{
    height: 166px;
    background: url("/assets/images2/battlefield/footer-bg.png") no-repeat top center / 100% 100%;
    //padding: 10px 20px;
    padding: 22px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    //grid-gap: 10px;
    //flex-shrink: 0;
    // height: 180px;

    .activeTime {
        height: 12px;
        line-height: 12px;
        font-size: 12px;
        color: #EDFFF6;
        text-align: center;
        margin-top: 8px;
    }
}

.apply_btn{
    display: flex;justify-content: center;align-items: center;gap: 4px;
    height: 60px;width: 100%;border-radius: 10px;border: unset;padding: 0; margin: 18px 0 0;
    //background: url("/assets/images/MiningPool/explore.png") no-repeat center/ 100% 100%;
    background: url("/assets/images2/battlefield/btn-bg.png") no-repeat center/ 100% 100%;
    .icon{ width: 28px; height: 28px;}
    span{
        //background: linear-gradient(180deg, #FFF 0%, #C3D0FF 100%);
        background: linear-gradient(180deg, #FFFFFF 0%, #E7FFEC 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-family: DINPro;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 1; /* 133.333% */
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
    transform: scale(1,1) translate(0px,0px);
    transition: all 0.1s ease;
    position: relative;
    overflow: hidden;
    &:hover{
        // transition: background 0.3s ease-in-out;
        transform:scale(0.98,0.98) translate(1px, 3px);
        transition: all 0.1s ease;
    }
}
.apply_btn.active{
    //background: url("/assets/images/MiningPool/exploreing.png") no-repeat center/ 100% 100%;
    background: url("/assets/images2/battlefield/btn-active.png") no-repeat center/ 100% 100%;
}


.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex:1;
    min-width: 32px;
    height: 33px;
    border: unset;
    padding: 0;
    margin: 0;
    //border-radius: 10px;
    border-radius: 4px;
    //border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    //background: linear-gradient(180deg, rgba(23, 49, 74, 0.80) 0%, rgba(49, 68, 89, 0.80) 100%);
    background: transparent;
    border-bottom: 1px solid #63FF7D;
    box-shadow: 0px -2px 8px 0px #B7F9AF4D inset;
    transform: scale(1,1) translate(0px,0px);
    transition: all 0.1s ease;
    position: relative;
    overflow: hidden;
    &:hover{
        // transition: background 0.3s ease-in-out;
        transform:scale(0.98,0.98) translate(1px, 3px);
        transition: all 0.1s ease;
    }
    .icon{ width: 14px; height: 14px;}
    span{
        background: linear-gradient(180deg, #FFF 0%, #A5B2BE 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-family: DINPro;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
}

.updata_btn{
    background: url("/assets/images/MiningPool/upgrade.png") no-repeat center/ 100% 100%;font-weight: 700;
}


.translate{
    transform: scale(1,1) translate(0px,0px);
    transition: all 0.1s ease;
    position: relative;
    overflow: hidden;
    &:hover{
        // transition: background 0.3s ease-in-out;
        transform:scale(0.98,0.98) translate(1px, 3px);
        transition: all 0.1s ease;
    }
}









