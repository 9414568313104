//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.navbar {
  &.bgImg {
    //background: url('/assets/images/navbar-bg.png') no-repeat center top/auto 100%;
    background: url("~@/assets/images2/navbar-bg.png") no-repeat top left;
    background-size: 100% 100%;
    .adm-nav-bar-title {
      background: linear-gradient(180deg, #FFF 33.33%, #7682A1 78.65%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .adm-nav-bar-left {
    color: var(--text-title);
  }
  .adm-nav-bar-right{
    color: var(--text-title);
  }
  .adm-nav-bar-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    height: 100%;
    display: flex; align-items: center;
    letter-spacing: 1px;
  }
  .adm-nav-bar-back-arrow{
    display: flex; align-items: center;
  }
  .adm-nav-bar-right{
    display: flex; align-items: center; justify-content: flex-end;
  }
}

.iconBg{fill: var(--model-color-white);}
.iconText{stroke:var(--text-desc);}







