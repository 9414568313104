//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.tabbar {
  //background: url('../../assets/images/tabbar-bg.png') no-repeat center bottom / 100% 100%;
  background: url('~@/assets/images2/tabbar-bg.png') no-repeat center bottom / 100% 100%;

  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 80;
  //height: 64px;
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  :global {
    .adm-tab-bar-wrap {
      //min-height: 64px;
      min-height: 72px;
    }
    .adm-tab-bar-item {
      //color: #4A5F76;
      color: #4E5E5E;
      .adm-tab-bar-item-icon {
        font-size: 28px;
        height: 28px;
      }
      &-active {
        //color: #FF8963;
        color: #63FF7D;
        font-weight: bold;
        &::after {
          position: absolute;
          content: '';
          display: block;
          //box-shadow: 0 20px 40px 30px rgba($color: #FF8963, $alpha: 0.33);
          box-shadow: 0 20px 40px 30px rgba($color: #63FF7D, $alpha: 0.4);
        }
      }
    }
  }
  .adm-tab-bar-item-title{
    margin-top: 4px;
  }
}

.content {
  //只能在子布局中定义高度。 这里定义的会影响动画效果
  //padding-top: 70px;
  height: 100%;
  //height: calc(100% - 50px);
  //padding-bottom: 64px;
  padding-bottom: 72px;
  overflow: auto;
  //background: url('/assets/images/page-bg.jpg') no-repeat center top/auto 100%;
  background: linear-gradient(90deg, #053036 0%, #084952 12.5%, #0A626D 32%, #076F7D 50%, #0A626D 68.5%, #084C55 87%, #053036 100%);
}
