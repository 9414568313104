//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.teamTop {
  margin-bottom: 20px;
  .teamName {
    font-size: 20px;
    font-weight: 700;
    margin-top: 8px;
    text-align: center;
    min-height: 29px;
  }
}

.avatarBox {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: 0 auto;
  .avatar {
    display: block;
    width: 150px;
    height: 150px;
  }
}

.teamInfo {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  padding: 0 12px;
  font-weight: 600;
  font-size: 16px;
  &.small {
    font-size: 12px;
    .teamInfoItem {
      padding: 5px 0;
    }
  }
  .teamInfoItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    &:not(:last-child) {
      border-bottom: 1px solid $colorBorder;
    }
  }
  .teamInfoItemLabel {
    color: $colorTextPrimary;
  }
  .teamInfoItemValue {
    width: max-content;
    flex-shrink: 0;
    margin-left: 15px;
  }

  .value {
    font-size: 20px;
  }
}

.tip {
  font-size: 12px;
  color: rgba(255, 221, 135, 1);
  margin-top: 12px;
}
