//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.wallet {
  .listScore {
    //margin-top: 12px;
    display: flex;
    //border-radius: 4px;
    //padding: 24px 0;
    background-image: url('../../../assets/images/mine/accountRect.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 12px 0;
    height: 86px;
    justify-content: center;
  }
  .itemScore {
    display: flex;
    align-items: center;
    gap: 10px;
    .itemScoreLabel {
      font-weight: 700;
      color: $colorTextSecondary;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
    .itemScoreValue {
      font-size: 20px;
      color: rgba(99, 255, 125, 1);
      font-weight: 700;
    }
    .info {
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
  }
}
.title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.assets {
  margin-top: 24px;
  .listAssets {
    margin-top: 12px;
    padding: 0 0px;
    border-radius: 4px;
  }
  .listPowers {
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }
  .itemPowers {
    display: flex;
    justify-content: space-between;
    width: 160px;
    align-items: center;
    padding: 5px 0;
    .itemPowersLabel {
      align-items: center;
      color: $colorTextSecondary;
      font-weight: 600;
      font-size: 14px;
    }
    .itemPowersValue {
      font-size: 14px;
      font-weight: 600;
      color: rgba(99, 255, 125, 1);
    }
    border-bottom: 1px solid $colorBorder;
  }
  .itemAssets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    .itemAssetsLabel {
      display: flex;
      align-items: center;
      color: $colorTextSecondary;
      font-weight: 600;
      font-size: 16px;
    }
    .itemAssetsValue {
      font-size: 16px;
      font-weight: 600;
      color: rgba(99, 255, 125, 1);
    }
    border-bottom: 1px solid $colorBorder;
  }
  .iconCoin {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

.btnGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 24px;
  button {
    border-bottom: 1px solid rgba(99, 255, 125, 1);
    box-shadow: 0px -2px 12px 0px rgba(183, 249, 175, 0.4) inset;
    background: transparent;
    border-bottom: 1px solid #63FF7D;
    transform: scale(1,1) translate(0px,0px);
    transition: all 0.1s ease;
    width: calc(50% - 12px);
    color: rgba(255, 255, 255, 1);
  }
}

