//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.register {
  color: #fff;
  :global(.adm-form) {
    --border-top: none;
    --border-bottom: none;
  }

  .form {
    border-top: none !important;
    border-bottom: none !important;
    :global {
      .adm-list-item {
        background: transparent;
        .adm-list-item-content {
          border-top: 0;
          padding: 0;
        }
      }
      .adm-list-body {
        background: transparent;
      }
      .adm-form-footer {
        padding: 0;
      }
      .adm-list-item-content-main {
        padding: 0;
      }
    }
    .formItem {
      margin-bottom: 24px;
      .itemTitle {
        font-size: 14px;
        font-weight: 600;
        color: $colorBlack;
        margin-bottom: 8px;
      }
    }
  }
  .tips {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin: 18px 0 32px;

    font-size: 14px;
    color: $colorGray;

    & > input {
      margin-right: 8px;
    }

    & > span {
      color: $colorBlue;
    }
  }
  :global(.adm-form-item-label) {
    font-size: 14px;
    font-weight: bold;
    color: #111827;
  }
  :global(.adm-list-item) {
    padding-left: 0;
  }
}

.registerPopup {
  //组件样式覆盖
  --max-width: 100%;
  --min-width: 100%;
  --border-radius: 0;

  .popupContainer {
    width: 100%;
    height: 430px;
    background: url("~@/assets/images2/splash/regsuccess-lighter.png") no-repeat center;
    background-size: 430px 430px;
    padding-top: 80px;
    box-sizing: border-box;
  }

  .popupTitle {
    font-size: 40px;
    font-weight: 800;
    background: linear-gradient(180deg, #FFF3AB 0%, #FEFDF9 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 5px;
  }

  .popupWrapper {
    width: 100%;
    height: 182px;
    background: #FFFFFF url("~@/assets/images2/splash/regsuccess-bg.png") no-repeat center;
    background-size: 100% 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .popupContent {
      font-size: 20px;
      color: #2D2D2D;
      text-align: center;
      line-height: 28px;
    }
  }

  .popupFooter {
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 25px;
  }

}

.registerPopupMask {
  background: rgba(0, 0, 0, 0.4) !important;
}

.registerPopupBody {
  background: transparent !important;
}
