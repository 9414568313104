//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

// .page{ background: #F9FAFB;height: 100%; width:100vw }
// .body{height: calc(100% - 50px); width: 100%; padding: 1px 0 10px;}

.btnBoxs {
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}


.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  min-height: 100%;
  padding: 20px;
  overflow: hidden;
  overflow-y: auto;
  .mainContentWrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
  }
}

.borderTop {
  //border-top: 1px solid $colorBorder;




  border-top: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #CCEADB 50%, rgba(255, 255, 255, 0) 100%);
}

.borderBottom {
  border-bottom: 1px solid $colorBorder;
}


.account {
  display: flex;
  background-image: url('../../assets/images/mine/accountRect.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: space-between;
  padding: 12px 0;
  height: 86px;
  font-size: 20px;
  .avatar {
    height: 56px;
    width: 56px;
    margin-left: 20px;
    margin-top: 4px;
  }
  font-weight: 700;
  .accontL {
    width: 100%;
    flex-shrink: 1;
    overflow: hidden;
    margin-right: 10px;
    margin-left: 10px;
    display: block;
    .accontLTop {
      display: flex;
      align-items: center;
      min-height: 26px;
    }
    .accontLBottom {
      display: flex;
      align-items: center;
      min-height: 26px;
    }
    .nicknameLabel {
      font-size: 16px;
      color: $colorTextSecondary;
      flex-shrink: 0;
    }
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-left: 3px;
      &.userId {
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
  .accontR {
    width: 28px;
    margin-right: 20px;
    flex-shrink: 0;
    margin-top: 3px;
  }
}

.account>* {
  display: flex;
  align-items: center;
  grid-gap: 4px;
}

.gridList {
  display: flex;
  grid-gap: 8px;

  .icon {
    width: 28px;
    height: 28px;
    font-size: 24px;
  }

  .gridItem{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex:1;
    min-width: 32px;
    height: 52px;
    border: unset;
    padding: 0;
    margin: 0;
    //border-radius: 10px;
    border-radius: 4px;
    //border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    //background: linear-gradient(180deg, rgba(23, 49, 74, 0.80) 0%, rgba(49, 68, 89, 0.80) 100%);
    background: transparent;
    border-bottom: 1px solid #63FF7D;
    box-shadow: 0px -2px 8px 0px #B7F9AF4D inset;
    transform: scale(1,1) translate(0px,0px);
    transition: all 0.1s ease;
    position: relative;
    overflow: hidden;
    &:hover{
        // transition: background 0.3s ease-in-out;
        transform:scale(0.98,0.98) translate(1px, 3px);
        transition: all 0.1s ease;
    }
    .icon{ width: 28px; height: 28px;}
    span{
        background: linear-gradient(180deg, #FFF 0%, #A5B2BE 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-family: DINPro;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
  }
}

.menuList {
  .icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 49.85%, rgba(255, 255, 255, 0) 100%);

  .menuItem {
    display: flex;
    padding: 16px 0;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
  }
}


.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: Fixed (335px)px;
  height: Hug (44px)px;
  padding: 12px 16px 12px 16px;
  gap: 4px;
  border: 0px 0px 1px 0px;

  border-radius: 4px;
  background: rgba(89, 124, 130, 0.2);
  border-bottom: 1px solid var(--d10, rgba(89, 124, 130, 1));
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  flex-shrink: 0;
}

.headerTitle {
  display: grid;
  place-items: center;
  width: 100vw;
  margin-top: 40px;
}

.logo {
  height: 56px;
  object-fit: contain;
}


.version {
  font-size: 12px;
  color: $colorTextThird;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: -10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  background-image: url('../../assets/images/common/header-title.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
