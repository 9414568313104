//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.adm-popup-body-position-bottom {
    border-radius: 32px 32px 0 0;

    .dialog-conetnt {
        max-height: calc(90vh - 45px);
        border-radius: 10px 10px 0px 0px;
        //background: var(--sq1, linear-gradient(180deg, #123556 0%, #071A2C 100%));
        background: linear-gradient(180deg, #006270 5.85%, #053036 100%);
        min-height: 120px;
        width: 100vw;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;

        .operate {
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;

            .action {
                width: 134px;
                height: 5px;
                border-radius: 100px;
                //background: $colorDivider;
                background: rgb(51,129,139);
            }
        }

        .header {
            .title {
                line-height: 1.5;
                padding: 0 24px
            }

            flex-shrink: 0;
        }

        .content {
            padding: 10px 20px;
            flex: 1;
            overflow: auto;
        }

        .footer{ padding: 0 20px; }
        .popupContent {
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
        }

        .popupList {
            display: flex;
            flex-direction: column;

            &>div {
                min-height: 30px;
                display: flex;
                align-items: center;
                font-size: 14px;
                grid-gap: 10px;
                color: var(--text-input);
            }
        }
    }
}
