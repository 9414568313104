//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.title {
  font-size: 20px;
  font-weight: bold;
}

.swiperItem {
  height: 160px;
  width: 100%;
  display: block;

  img {
    width: 100%;
    height: 100%;
  }
}

.main {
  padding: 20px;
  position: relative;
}

.gapPrice {
  display: flex;
  justify-content: space-between;
  //border-radius: 4px;
  //background: url('../../assets/images/gapPrice-bg.png') no-repeat center center;
  //background-size: cover;
  //box-shadow: 0px -1px 0px 0px $colorPrimary inset, 0px 4px 4px 0px rgba(255, 137, 99, 0.25);
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #CCEADB 50%, rgba(255, 255, 255, 0) 100%);
  border-image-slice: 1;
  //padding: 12px;
  color: #fff;
  padding: 8px;

  .gapPriceLeft {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #FFFFFF;
    //font-weight: 700;

    img {
      //width: 50px;
      //height: 50px;
      width: 32px;
      height: 32px;
      margin-right: 4px;
    }

    .label {
      //color: var(--a2, #C2D6ED);
      font-size: 12px;
      //font-weight: 300;
      color: #EDFFF6;
    }
  }

  .gapPriceRight {
    display: flex;
    align-items: center;
  }
}

.charts {
  border-bottom: 1px solid transparent;
  border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #CCEADB 50%, rgba(255, 255, 255, 0) 100%);
  border-image-slice: 1;
}

.btnRecharge {
  padding: 6px;

  > span {
    display: flex;
    align-items: center;
    line-height: 1;
    gap: 2px;
  }
}

.recommendNull {
  height: 306px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recommend {
  position: relative;
  //height: 216px;
  height: 306px;
  padding: 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("~@/assets/images2/splash/comb-bg.png") no-repeat center;
  background-size: 100% auto;
  //border-radius: 4px;
  //overflow: hidden;
  //border-bottom: 1px solid var(--d, $colorPrimary);
  //box-shadow: 0px -1px 0px 0px $colorPrimary inset, 0px 4px 4px 0px rgba(255, 137, 99, 0.25);
  //background: linear-gradient(180deg, #1B1C20 0%, #1E2024 36.46%, #232428 54.69%, #212127 73.96%, #2A2A2E 100%);
  //text-align: right;

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    z-index: 1;
    height: 25px;
    background: url('~@/assets/images2/splash/top-line.png') no-repeat top left;
    background-size: 100% auto;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: -10px;
    right: -10px;
    z-index: 1;
    height: 25px;
    background: url('~@/assets/images2/splash/bot-line.png') no-repeat bottom left;
    background-size: 100% auto;
  }

  > img {
    //height: 100%;
    width: 160px;
    height: 160px;
    object-fit: cover;
    //margin-right: 20px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 3px;
    overflow: hidden;
  }

  .recommendItemInfoTitle {
    font-size: 20px;
    text-align: center;
    padding: 2px 4px;
    //font-weight: 600;
    border-bottom: 1px solid transparent;
    border-image-source: url("~@/assets/images2/banner-line.png");
    border-image-slice: 1;
    border-image-repeat: stretch;
    margin-top: 10px;
  }

  .recommendItemInfoPrice {
    display: flex;
    align-items: center;
    margin-top: 8px;
    //width: max-content;
    //font-weight: 700;
    padding: 1px 4px;
    border-bottom: 1px solid transparent;
    border-image-source: url("~@/assets/images2/banner-line.png");
    border-image-slice: 1;
    border-image-repeat: stretch;
    .recommendItemInfoPriceNum {
      font-size: 20px;
      margin-left: 5px;
    }
  }

  //.recommendItemInfo {
  //  //position: absolute;
  //  //top: 0;
  //  //left: 0;
  //  //color: #fff;
  //  //padding: 20px;
  //  //height: 100%;
  //  display: flex;
  //  flex-direction: column;
  //  font-size: 20px;
  //  align-items: center;
  //  justify-content: center;
  //
  //  .recommendItemInfoTitle {
  //    font-size: 20px;
  //    text-align: center;
  //    font-weight: 700;
  //  }
  //
  //  .recommendItemInfoPrice {
  //    margin-top: 16px;
  //    text-align: center;
  //    width: max-content;
  //    font-weight: 700;
  //  }
  //}

  .btnBuy {
    width: 90px;
    margin-top: 16px;
  }
}

.myPower {
  //display: flex;
  //height: 70px;
  //border-radius: 4px;
  //background: url('../../assets/images/myPower-bg.png') no-repeat center center;
  //background-size: cover;
  //box-shadow: 0px -1px 0px 0px $colorPrimary inset, 0px 4px 4px 0px rgba(255, 137, 99, 0.25);

  .myPowerItem {
    position: relative;
    //width: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    //padding: 0 12px;

    &:first-child {
      margin-bottom: 15px;

      &::after {
        //position: absolute;
        //right: 0;
        //content: '';
        //display: block;
        //width: 1px;
        //height: 54px;
        //border-radius: 8px;
        //background: #345473;
      }
    }
  }

  .myPowerItemTitle {
    //font-size: 12px;
    flex: 1;
    display: flex;
    align-items: center;
    //color: $colorTextSecondary;
    //font-weight: 700;
    color: #FFFFFF;
  }

  .myPowerItemNum {
    font-size: 16px;
    //font-weight: 700;
    color: #63FF7D;
  }

  .myPowerItemRight {
    flex: 1;
    display: flex;
    align-items: center;
    height: 24px;
    font-size: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    margin-left: 2px;

    .IconInfo {
      flex-shrink: 0;
    }
  }
}

.rank {

  .rankTitle {
    position: relative;
    color: #fff;
    font-size: 16px;
    //font-weight: 700;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    border-bottom: 1px solid #f8f8f2;

    span {
      margin-left: 5px;
    }

    //&::after {
    //  position: absolute;
    //  bottom: 0;
    //  left: 0;
    //  width: 100%;
    //  content: '';
    //  display: block;
    //  height: 1px;
    //  width: 100%;
    //  border-radius: 8px;
    //  background: $colorPrimary;
    //  box-shadow: 0px -2px 5.1px 0px $colorPrimary;
    //}
  }

  .rankList {
    padding: 0 8px;
    box-sizing: border-box;
    .rankItem {
      height: 40px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      //justify-content: center;
      font-size: 14px;
      color: #EDFFF6;
      padding-left: 105px;
      box-sizing: border-box;
      .rankItemRank {
        font-size: 16px;
      }
      .rankItemImg {
        width: 24px;
        height: 24px;
        margin-left: 12px;
      }
      .rankItemName {
        margin-left: 10px;
      }
    }
  }

  .rankTop3 {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-top: 10px;
    gap: 10px;
    padding: 0 30px;

    .rankTop3Item {
      text-align: center;
      font-size: 12px;
      width: calc(30%);
      flex-shrink: 0;

      .rankTop3ItemRank {
        font-size: 12px;
        color: #fff;
        font-weight: 700;
      }

      .rankTop3ItemInfoAvatar {
        width: 44px;
        height: 44px;
        margin-top: 2px;
        display: block;
        border-radius: 2px;
        margin: 4px auto 0;
      }

      .rankTop3ItemInfoName {
        color: #C2D6ED;
        margin-top: 3px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &.one {
        width: calc(40%);

        .rankTop3ItemInfoAvatar {
          width: 56px;
          height: 56px;
        }
      }
    }
  }

  .rankOther {
    display: flex;
    margin-top: 8px;
    padding: 0 24px;
    gap: 12px;

    .rankOtherItem {
      display: flex;
      align-items: center;
      font-size: 12px;
      width: 137px;
      padding: 8px 4px;
      gap: 8px;
      border-top: 1px solid rgba(255, 255, 255, 0.10);
    }

    .rankOtherItemRank {
      color: #fff;
      font-weight: 700;
    }

    .rankOtherItemInfo {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 8px;
    }

    .rankOtherItemInfoAvatar {
      width: 28px;
      height: 28px;
      border-radius: 2px;
    }

    .rankOtherItemInfoName {
      color: #C2D6ED;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.rankEmptyText {
  color: $colorTextSecondary;
  font-size: 16px;
  text-align: center;
  padding: 15px 0;
}

.swiperWrapper {
  height: 162px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $colorPrimary;
  border-bottom: 1px solid $colorPrimary;
  //border-image: url("~@/assets/images2/banner-line.png") 30 30 stretch;
  border-image-source: url("~@/assets/images2/banner-line.png");
  //background: url("~@/assets/images2/banner-line.png") no-repeat top left;
  //background-size: 100% 1px;
  //border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: stretch;
  //border-width: 1px 0 1px 0;
}
