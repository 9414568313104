//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.locales {
  padding: 0;
  .radioGroup {
    margin-top: 20px;
  }
  :global(.adm-radio) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $colorBorder;
    padding: 16px 20px;
    &:last-child {
      border-bottom: 1px solid $colorBorder;
    }
  }
}

.item {
  color: var(--text-title);
  display: flex;
  align-items: center;
  justify-content: center;
}

// .item:last-child{ 
//   border: unset;
// }
