//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.current {

}

.switch {
  width: 100%;

  & > button {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    min-height: 60px;

    border: none;
    background: linear-gradient(180deg, #ECF2FF 0%, #C0D8FF 100%);
    color: $colorBlue;
    border-radius: 12px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;

    text-align: center;
    letter-spacing: 0.3px;

    transition: background 0.3s ease-in-out;

    &:active {
      opacity: 0.8;
    }

    &:nth-child(n+2) {
      margin-top: 10px;
    }

    &:disabled {
      opacity: 0.6;
    }
  }
}