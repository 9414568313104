//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.splash {
  //background: linear-gradient(180deg, #123556 0%, #071A2C 100%);
  background: linear-gradient(180deg, #006270 5.85%, #053036 100%);
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    &>img{
      //height: 28px;
      height: 16px;
    }
    .btnLan {
      position: absolute;
      right: 24px;
      width: 24px;
      height: 24px;
    }
  }

  .body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #fff;

    padding: 15px;
    box-sizing: border-box;
    &>img{
      width: 100%;
    }
  }
  .main {
    //height: 396px;
    //padding: 39px 8px;
    padding: 8px;
    box-sizing: border-box;
    position: relative;

    //&:before {
    //  content: '';
    //  width: 100%;
    //  height: 25px;
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  z-index: 1;
    //  background: url("~@/assets/images2/splash/top-line.png") no-repeat top left;
    //  background-size: 100% auto;
    //}

    .topLine {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .botLine {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .wrapper {
      height: 396px;
      padding: 36px 0;
      box-sizing: border-box;
      background: linear-gradient(180deg, rgba(177, 177, 177, 0) -0.06%, rgba(255, 255, 255, 0.05) 100%);
      text-align: center;

      .splashBox {
        background: url("~@/assets/images2/splash/main-bg.png") no-repeat center center;
        background-size: 100% auto;
      }
    }

  }
  .splashImg {
    width: 240px;
    margin: 0 auto;
  }

  .title {
    text-align: center;
    //padding: 0 48px;
    padding: 0;
    margin-top: 20px;
  }

  .action {
    //justify-content: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding: 0 24px;
    padding: 0 9px;
    min-height: 150px;

    & > :nth-child(2) {
      margin-top: 16px;
    }
  }
}
