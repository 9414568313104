//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.adm-form-item-feedback-error {
  font-size: 12px;
}
.adm-list-default .adm-list-body {
  border: 0;
}
.adm-list , .adm-input{
  --font-size: var(--adm-font-size-8);
}
.adm-selector {
  --gap: 12px;
  ---gap: var(--gap);
}
.adm-popup-body {
  border-radius: 10px 10px 0px 0px;
  //background: var(--sq1, linear-gradient(180deg, #123556 0%, #071A2C 100%));
  background: #015460;
}
// 时间选择器
.adm-picker {
  .adm-picker-header-button {
    color: #fff;
  }
  .adm-picker-view {
    //background: var(--sq1, linear-gradient(180deg, #123556 0%, #071A2C 100%));
    background: #015460;
    .adm-picker-view-mask {
      .adm-picker-view-mask-top {
        background: #015460;
      }
      .adm-picker-view-mask-bottom {
        background: #015460;
      }
    }
  }
}

// 自定义兑换记录样式（CommonPageScrollList 里的 ResultPage content 样式）
.exchangeRecordContent {
  min-height: 180px;
}
.adm-center-popup-body {
  background: var(--sq1, linear-gradient(180deg, #123556 0%, #071A2C 100%));
  .adm-button:not(.adm-button-default) {
    background-color: #233954;
    box-shadow: 0px -1px 0px 0px #4F6176 inset;
    border: 0;
    border-radius: 10px;
    height: 50px;
    min-height: 50px;
    font-size: 16px;
    font-weight: 600;
  }
  .adm-modal-title {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .adm-modal-content {
    padding-bottom: 30px;
  }
  .adm-modal-footer {
    padding: 0 20px 20px;
  }
}
