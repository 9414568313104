//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.content {
  padding-bottom: 6px;

  .actions {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    border-top: 1px solid $colorBorder;
    padding-top: 16px;
    & > :last-child {
      margin-left: 14px;
    }
  }
}

.timeRangePicker {
  display: flex;
  flex-direction: row;
  align-items: center;

  //border-bottom: 1px solid $colorDivider;
  margin-top: 10px;


  .center {
    width: 10px;
    height: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    .centerInner{
      width: 8px;
      height: 1px;
      border-bottom: 1px solid #fff;
      flex-shrink: 0;
    }
  }

}
.confirm {
  background: rgba(255, 255, 255, 1);
}
