//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.record {
  .curItem {
    background: linear-gradient(89.18deg, rgba(99, 255, 125, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-bottom: 1px solid rgba(99, 255, 125, 1);
    border-left: 1px solid rgba(99, 255, 125, 1);
  }
  .item {
    background: linear-gradient(89.18deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    border-left: 1px solid rgba(255, 255, 255, 1);
  }
  .listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px 0px 0px 4px;

    .content {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
  
      .left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
    }
  
    .icon{
      width: 16px;
      height: 16px;
      font-weight: 600;
    }
    .listContent {
      justify-content: space-between;
      width: 100%;
      margin-left: 12px;
      .itemTitle {
        font-weight: 700;
        color: $colorTextSecondary;
        display: flex;
        justify-content: space-between;
        padding-bottom: 6px;
        border-bottom: 1px solid $colorBorder;
        .itemLabel {
          color: $colorTextSecondary;
        }
        .itemValue {
          font-weight: 700;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          &.negative {
            color: $colorTextThird;
          }
        }
      }
      .ItemFooter {
        font-size: 12px;
        color: $colorTextSecondary;
        padding-top: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .fee {
          font-size: 12px;
          font-weight: 700;
          &.negative {
            color: $colorTextThird;
          }
        }
      }
    }
  }
}
