//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.createSubAccount {
  color: #fff;
  :global(.adm-form) {
    --border-top: none;
    --border-bottom: none;
  }

  .form {
    border-top: none !important;
    border-bottom: none !important;
    :global {
      .adm-list-item {
        background: transparent;
        .adm-list-item-content {
          border-top: 0;
          padding: 0;
        }
      }
      .adm-list-body {
        background: transparent;
      }
      .adm-form-footer {
        padding: 0;
      }
      .adm-list-item-content-main {
        padding: 0;
      }
    }
    .formItem {
      margin-bottom: 20px;
      .itemTitle {
        font-size: 14px;
        font-weight: 600;
        color: $colorBlack;
        margin-bottom: 8px;
      }
    }
  }
  .tips {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin: 18px 0 32px;

    font-size: 14px;
    color: $colorGray;

    & > input {
      margin-right: 8px;
    }

    & > span {
      color: $colorBlue;
    }
  }
  :global {
    .adm-form-item.adm-form-item-vertical {
      .adm-form-item-label {
        font-size: 16px;
        color: #fff;
        margin-bottom: 10px;
      }
    }
  }
  
  :global(.adm-list-item) {
    padding-left: 0;
  }
  .tip {
    font-size: 12px;
    color: rgba(255, 221, 135, 1);

    margin-top: 20px;
  }
}
