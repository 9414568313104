//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.button {
  width: max-content;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //background: $colorPrimary;
  background: rgba(0,0,0,0.2);
  border: 1px solid rgba(255, 255, 255, 0.15);
  //border-radius: 4px;
  border-radius: 2px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  padding: 0 8px;
  //box-shadow:0px -1px 0px 0px var(--borderColor) inset;
}

.danger {
  //background: $colorRed;
  color: #FFDD87;
}

