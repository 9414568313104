//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  background: $colorAliceBlue;
  border: 1px solid $colorBorder;
  border-radius: 12px;

  transition: background .1s linear;

  .coin {
    width: 48px;
    height: 48px;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: $colorBlack;

    flex-grow: 1;
    margin: 0 16px;

    transition: color .1s linear;
  }

  .radio {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: url("~assets/images/icon/Radio.png");
    background-size: cover;
    transition: background .1s linear;
  }

  &:active {
    opacity: 0.8;
  }
}

.contentSelected {
  background: $colorBlue;
  border: 1px solid $colorBlue;

  .title {
    color: $colorWhite;
  }

  .radio {
    background: url("~assets/images/icon/RadioSelected.png");
    background-size: cover;
  }
}

.contentDisabled {
  opacity: 0.5;

  &:active {
    opacity: 0.5;
  }
}