//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.teamInfo {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  padding: 0 12px;
  font-weight: 700;
  margin-bottom: 20px;
  &.small {
    font-size: 12px;
    .teamInfoItem {
      padding: 5px 0;
    }
  }
  .teamInfoItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    &:not(:last-child) {
      border-bottom: 1px solid $colorBorder;
    }
  }
  .teamInfoItemLabel {
    color: $colorTextPrimary;
  }

  .value {
    font-size: 20px;
  }
}

.teamItem {
  border-radius: 4px;
  background: linear-gradient(180deg, #16314E 0%, #2D5072 100%);
  padding: 0 12px;

  &>div+div {
      border-top: 1px solid rgba(255, 255, 255, 0.10);
  }

  .teamItemTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;

      .itemLabel {
          font-weight: 700;
      }

      .itemValue {
          color: $colorTextPrimary;
      }
  }

  .teamItemBottom {
      padding: 8px 0;
      color: $colorTextSecondary;

      .btnCopy {
          margin-left: 4px;
          width: 14px;
          height: 14px;
          color: $colorTextSecondary;
      }
  }
}

.teamTitle {
  border-bottom: 1px solid $colorBorder;
  padding-bottom: 4px;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  &.small {
    font-size: 12px;
  }
}

.teamBox {
  display: flex;
  flex-direction: column;
}

.avatar {
  width: 140px;
  height: 140px;
}

.avatarBox {
  width: 140px;
  height: 140px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 0px rgba(255, 255, 255, 0.25) inset;
  overflow: hidden;
  margin: 0 auto;
  background: #16314E;
}

.teamTop {
  margin-bottom: 12px;
  .teamName {
    font-size: 20px;
    font-weight: 700;
    margin-top: 8px;
    text-align: center;
    min-height: 29px;
  }
}

.btnSetting {
  width: 24px;
  height: 24px;
}

.subordinateList {
  .subordinateItem {
    border-radius: 4px;
    border-top: 1px solid rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 0.1);
    padding: 0 12px;
    margin-bottom: 12px;
    &.subordinateItemChildren {
      margin-left: 15px;
      background: #2A5075;
      &.even {
        background: #385471;
      }
    }
    &Top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;
      &L {
        display: flex;
        align-items: center;
      }
      &R {
        color: $colorTextSecondary;
        font-size: 12px;
        width: max-content;
        flex-shrink: 0;
      }
    }
    &Bottom {
      padding: 8px 0;
      color: $colorTextSecondary;
      border-top: 1px solid $colorBorder;
      font-size: 12px;
      &Item {
        display: flex;
        justify-content: space-between;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
    .btnCollapse {
      width: 19px;
      height: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: $colorDivider;
      margin-right: 8px;
      &.hide {
        background: linear-gradient(180deg, #A181FF 0%, #44A1A7 100%);
      }
    }
  }
}
.childrenList {
  transition: height 0.4s linear;
  &.show {
    .subordinateItemWraper {
      &:last-child {
        margin-bottom: 24px;
      }
    }
  }
}
// 实现通过height来控制显示隐藏
.show {
  height: auto;
  overflow: visible;
}
.hide {
  height: 0;
  overflow: hidden;
}

.actions {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  border-top: 1px solid $colorBorder;
  padding-top: 16px;
  & > :last-child {
    margin-left: 14px;
  }
}

.tip {
  font-size: 12px;
  color: $colorTextSecondary;
  margin-top: 5px;
}

.form {
  :global {
    .adm-list-body {
      border: 0;
    }
    .adm-list-item {
      padding: 0;
    }
    .adm-list-body, .adm-list-item {
      background: transparent;
    }
    .adm-list-item-content {
      padding: 0;
      border: 0;
    }
    .adm-list-item-content-main {
      padding: 10px 0;
    }
  }
  .btnGroup {
    display: flex;
    width: calc(100% + 40px);
    margin: 0 -20px;
    button {
      margin: 0 10px;
    }
  }
  .maxLevelTip {
    font-size: 12px;
    color: $colorTextSecondary;
    text-align: center;
  }
}
