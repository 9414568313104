//定义全局scss变量文件

$colorPrimary: #FF8963;
$colorBlack: #111827;
$colorBlue:#3784FB;
$colorWhite: #FFFFFF;
$colorAliceBlue: #F9FAFB; //背景色
$colorGray:#9CA3AF;
$colorDivider:#466791;
$colorBorder:rgba(255, 255, 255, 0.20);
$colorLavenderBlue:#F0F5FF;
$colorRed:#FF8963;

// 主要文本
$colorTextPrimary: #fff;
// 二级文本
$colorTextSecondary: #C2D6ED;
// 三级文本
$colorTextThird: #83A0C2;

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .image {
    width: 155px;
    height: 155px;
  }

  .tips1 {
    margin-top: 30px;
    font-style: normal;
    font-size: 18px;
    color: $colorWhite;
  }

  .tips2 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    color: $colorGray;
  }

  .btn1 {
    margin-top: 80px;
  }

  .btn2 {
    margin-top: 20px;
  }
}